/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Button, Divider } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Služba hospodyňka"}>
        <SiteHeader />

        <Column className="--center pb--30 pt--30" name={"uvod"} style={{"backgroundColor":"rgba(0,92,183,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} style={{"maxWidth":""}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s4 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"4"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--36 title-box--invert" style={{"maxWidth":""}} content={"&nbsp;Služba hospodyňka"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--02 pt--20" name={"t9u0d5u0wv"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--20" content={"<span style=\"color: var(--color-supplementary); font-weight: bold;\">Nabízíme pomoc v domácnosti s běžnými úkony.</span>"}>
              </Title>

              <Text className="text-box text-box--center fs--18" content={"<span style=\"color: var(--color-supplementary);\">Ať už se jedná o <span style=\"font-weight: bold;\">praní</span>, <span style=\"font-weight: bold;\">žehlení</span> nebo <span style=\"font-weight: bold;\">pomoc s dětmi</span> a vašimi <span style=\"font-weight: bold;\">domácími mazlíčky</span>.<br>Za x let v tomto oboru jsem nasbírala ty nejlepší kontakty, které vám ráda předám.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--12" name={"8j57kgcv1kp"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17432/2c9c2c585e72421bbbf76c007862ca1e_e=173x0x1756x1317_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17432/2c9c2c585e72421bbbf76c007862ca1e_e=173x0x1756x1317_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/2c9c2c585e72421bbbf76c007862ca1e_e=173x0x1756x1317_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17432/2c9c2c585e72421bbbf76c007862ca1e_e=173x0x1756x1317_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17432/2c9c2c585e72421bbbf76c007862ca1e_e=173x0x1756x1317_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--24" content={"<span style=\"color: var(--color-supplementary);\">Praní</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17432/86e80f24cece4cf3ba2d565b9f58200a_e=179x0x1764x1323_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17432/86e80f24cece4cf3ba2d565b9f58200a_e=179x0x1764x1323_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/86e80f24cece4cf3ba2d565b9f58200a_e=179x0x1764x1323_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17432/86e80f24cece4cf3ba2d565b9f58200a_e=179x0x1764x1323_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17432/86e80f24cece4cf3ba2d565b9f58200a_e=179x0x1764x1323_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--24" content={"<span style=\"color: var(--color-supplementary);\">Žehlení</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17432/18be66c4fd5748988830f18e21777514_e=200x0x1758x1318_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17432/18be66c4fd5748988830f18e21777514_e=200x0x1758x1318_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/18be66c4fd5748988830f18e21777514_e=200x0x1758x1318_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17432/18be66c4fd5748988830f18e21777514_e=200x0x1758x1318_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17432/18be66c4fd5748988830f18e21777514_e=200x0x1758x1318_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--24" content={"<span style=\"color: var(--color-supplementary);\">Hlídání dětí</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--06 pt--20" name={"li1itrclsq"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17432/68d163e7ec9a45779a6b696474ff6816_e=247x0x1753x1314_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17432/68d163e7ec9a45779a6b696474ff6816_e=247x0x1753x1314_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/68d163e7ec9a45779a6b696474ff6816_e=247x0x1753x1314_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17432/68d163e7ec9a45779a6b696474ff6816_e=247x0x1753x1314_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17432/68d163e7ec9a45779a6b696474ff6816_e=247x0x1753x1314_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--24" content={"<span style=\"color: var(--color-supplementary);\">Vaření</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17432/0d106f07acea42e1876b6ab75462b96f_e=233x0x1767x1326_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17432/0d106f07acea42e1876b6ab75462b96f_e=233x0x1767x1326_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/0d106f07acea42e1876b6ab75462b96f_e=233x0x1767x1326_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17432/0d106f07acea42e1876b6ab75462b96f_e=233x0x1767x1326_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17432/0d106f07acea42e1876b6ab75462b96f_e=233x0x1767x1326_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--24" content={"<span style=\"color: var(--color-supplementary);\">Doučování</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17432/2d389d28a8e447118fb2d0149a0cc132_e=0x0x1751x1313_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/17432/2d389d28a8e447118fb2d0149a0cc132_e=0x0x1751x1313_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/2d389d28a8e447118fb2d0149a0cc132_e=0x0x1751x1313_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17432/2d389d28a8e447118fb2d0149a0cc132_e=0x0x1751x1313_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17432/2d389d28a8e447118fb2d0149a0cc132_e=0x0x1751x1313_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--24" content={"<span style=\"color: var(--color-supplementary);\">Venčení</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--60" name={"xushvy97858"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Osobně vám mohu nabídnout službu úklid šatníku."}>
              </Title>

              <Text className="text-box fs--18" content={"Ráda vám přijedu pomoci s vaším šatníkem. O vaší představě se můžeme domluvit např. u kávy.<br>Nabízím <span style=\"font-weight: bold;\">uskladnění vašich věcí</span>, <span style=\"font-weight: bold;\">pomoc se skládáním do skříně</span>, <span style=\"font-weight: bold;\">výměnu zimních věcí za letní</span> a naopak.<br>Dále <span style=\"font-weight: bold;\">konzultaci</span>, co je <span style=\"font-weight: bold;\">aktuálně v módě</span>, případně vám mohu <span style=\"font-weight: bold;\">pomoci i s prodejem věcí</span>,<br>které už se z nějakého důvodu do vašeho šatníku nehodí."}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/t/17432/519b1404ed364952bfb2fefb21045eae_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/17432/519b1404ed364952bfb2fefb21045eae_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17432/519b1404ed364952bfb2fefb21045eae_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17432/519b1404ed364952bfb2fefb21045eae_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17432/519b1404ed364952bfb2fefb21045eae_s=1400x_.jpg 1400w"}>
              </Image>

              <Button className="btn-box" url={"https://uklidnaklik.inrs.cz/rs/"} href={"https://uklidnaklik.inrs.cz/rs/"} style={{"backgroundColor":"var(--color-custom-1)"}} content={"<span style=\"font-weight: 700;\">Rezervace služby hospodyňka</span><br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"paticka"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Divider className="mt--20">
              </Divider>

              <Title className="title-box title-box--center fs--36" content={"<span style=\"font-weight: bold;\"><br>Kontakt:</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center fs--20" content={"<span style=\"color: var(--color-supplementary);\"><span style=\"font-weight: bold;\">+420</span> <span style=\"font-weight: bold;\">734 148 215</span></span><br>"}>
              </Text>

              <Text className="text-box text-box--center fs--20" style={{"marginTop":0}} content={"<a href=\"mailto:uklidnaklik@gmail.com?subject=Popt%C3%A1vka+z+webu\" style=\"font-weight: bold; color: var(--color-supplementary);\">uklidnaklik@gmail.com</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}